jQuery(function () {
	var myOffside = offside("#mobileMenu", {
		slidingElementsSelector: "body",
		buttonsSelector: ".menu-toggle, .offcanvas-close, .offcanvas-overlay",
		beforeOpen: function () {
			$(".offcanvas-overlay").fadeIn();
		},
		beforeClose: function () {
			$(".offcanvas-overlay").fadeOut();
		},
	});

	if ($(window).width() > 1024) {
		$(window).on("scroll", function () {
			if ($(window).scrollTop() > 0) {
				$("header").addClass("is-scrolling");
			} else {
				$("header").removeClass("is-scrolling");
			}
		});

		$(".home-field-list .item-col").each(function () {
			$(this).hover(
				function () {
					$(this).find('.item').addClass('active')
				},
				function () {
					$(this).find('.item').removeClass('active')
				}
			);
		});

		try {
			$('.history-wrap').css({
				'padding-left': $('.about-2 .container').offset().left + 15
			})
			$('.staff-list-wrap').css({
				'padding-left': $('.about-2 .container').offset().left + 15
			})

			window.dispatchEvent(new Event("resize"));
		} catch (error) {

		}
		if ($('.aboutpage').length > 0) {
			$(window).resize(function () {
				try {
					$('.history-wrap').css({
						'padding-left': $('.about-2 .container').offset().left + 15
					})
					$('.staff-list-wrap').css({
						'padding-left': $('.about-2 .container').offset().left + 15
					})
					window.dispatchEvent(new Event("resize"));
				} catch (error) {

				}
			})
		}

	} else {
		if ($('.project-detail-form').length > 0) {
			$('.cta-fixed-wrap ul').append(`<li><a class="cta-button project-detail-form-toggle" href="#"><em class="far fa-headset"></em></a></li>`)

			$('.project-detail-form-toggle').on('click', function (e) {
				e.preventDefault()
				$('.project-detail-form').slideToggle()
			})
		}
	}

	$(".tabs-nav a").click(function (e) {
		e.preventDefault()
		var idHover = $(this).attr("href");
		$(this).parent().addClass('active')
		$(this).parent().siblings().removeClass('active')
		$(`.item-tab${idHover}`).show();
		$(`.item-tab${idHover}`).siblings().hide();
	});

	$('.tabs-nav').each(function name() {
		$(this).find('a').eq(0).trigger('click')
	})

	$(".back-to-top").on("click", function () {
		$("html, body").animate({
			scrollTop: 0
		}, 1000);
	});

	$(".scroll-mouse").click(function (e) {
		e.preventDefault();
		scrollToElement($(this).attr("href"), 1000);
	});

	$(".about-nav ul li a").click(function (e) {
		e.preventDefault();
		scrollToElement($(this).attr("href"), 1000);
	});
	collapseComponent()

});

var scrollToElement = function (el, ms) {
	var speed = ms ? ms : 2000;
	$("html,body").animate({
		scrollTop: $(el).offset().top - $("header").height(),
	},
		speed
	);
};

const menuMapping = new MappingListener({
	selector: ".header-bg-menu-wrapper",
	mobileWrapper: ".offcanvas-wrap",
	mobileMethod: "appendTo",
	desktopWrapper: ".header-bg .header-bottom",
	desktopMethod: "prependTo",
	breakpoint: 1024.98,
}).watch();

const utilMapping = new MappingListener({
	selector: ".header-bg-top",
	mobileWrapper: ".offcanvas-wrap",
	mobileMethod: "appendTo",
	desktopWrapper: ".header-bg .header-bottom",
	desktopMethod: "insertBefore",
	breakpoint: 1024.98,
}).watch();

const languageMapping = new MappingListener({
	selector: ".language-wrapper",
	mobileWrapper: ".menu-toggle",
	mobileMethod: "insertBefore",
	desktopWrapper: ".header-bg-top",
	desktopMethod: "appendTo",
	breakpoint: 1024.98,
}).watch();

function collapseComponent() {
	$(document).on("click", ".collapse-trigger", function () {
		$(this).next().slideToggle();
		$(this).toggleClass("active");
		return false;
	});
}

const bannerHomeSlider = new Swiper(".home-banner .swiper-container", {
	spaceBetween: 10,
	loop: true,
	pagination: {
		el: ".home-banner .swiper-pagination",
	},
	autoplay: {
	  delay: 10000,
	}
	
});

try {
	$(".home-banner .swiper-fraction .current").text(
		bannerHomeSlider.realIndex + 1
	);
} catch (error) { }

bannerHomeSlider.on("slideChange", function () {
	$(".home-banner .swiper-fraction .current").text(
		bannerHomeSlider.realIndex + 1
	);
});

const projectSlider = new Swiper(".project-slider .swiper-container", {
	spaceBetween: 30,
	slidesPerView: 3,
	navigation: {
		prevEl: ".project-slider .swiper-prev",
		nextEl: ".project-slider .swiper-next"
	},
	breakpoints: {
		1024: {
			slidesPerView: 2.5
		},
		768: {
			slidesPerView: 1.75,
		},
		576: {
			slidesPerView: 1.25,
			spaceBetween: 15
		},
	},
});

var partnerSlider = new Swiper(".partner-slider .swiper-container", {
	slidesPerView: 3,
	slidesPerColumn: 2,
	spaceBetween: 20,
	navigation: {
		prevEl: ".partner-slider .swiper-prev",
		nextEl: ".partner-slider .swiper-next",
	},
	breakpoints: {
		1024: {
			slidesPerView: 4,
		},
		768: {
			slidesPerView: 2,
		}
	},
});

const newsSlider = new Swiper(".news-slider .swiper-container", {
	slidesPerView: 3,
	spaceBetween: 30,
	breakpoints: {
		1024: {
			slidesPerView: 2,
			spaceBetween: 30,
		},
		576: {
			slidesPerView: 1.25,
			spaceBetween: 15,
		},
	},
	navigation: {
		nextEl: ".news-slider .swiper-next",
		prevEl: ".news-slider .swiper-prev",
	},
});

const videoSlider = new Swiper(".video-slider .swiper-container", {
	slidesPerView: 1.25,
	spaceBetween: 12,
	centeredSlides: true,
	initialSlide: 1,
	loop: true,
	navigation: {
		prevEl: ".video-slider .swiper-prev",
		nextEl: ".video-slider .swiper-next",
	},
});

const projectFacilitySlider = new Swiper(".project-detail-facility .swiper-container", {
	slidesPerView: 5,
	navigation: {
		prevEl: ".project-detail-facility .swiper-prev",
		nextEl: ".project-detail-facility .swiper-next"
	},
	breakpoints: {
		1024: {
			slidesPerView: 4
		},
		768: {
			slidesPerView: 3,
		},
		576: {
			slidesPerView: 2,
		},
	},
});

const historyYearSlider = new Swiper(
	".history-year-slider .swiper-container", {
	slidesPerView: 3.75,
	breakpoints: {
		1024: {
			slidesPerView: 2.5
		},
		768: {
			slidesPerView: 1.75,
		},
		576: {
			slidesPerView: 1.25,
			spaceBetween: 15
		},
	},
	navigation: {
		prevEl: ".about-2 .swiper-prev",
		nextEl: ".about-2 .swiper-next"
	},
}
);

const historyContentSlider = new Swiper(
	".history-content-slider .swiper-container", {
	slidesPerView: 3.75,
	spaceBetween: 30,
	breakpoints: {
		1024: {
			slidesPerView: 2.5
		},
		768: {
			slidesPerView: 1.75,
		},
		576: {
			slidesPerView: 1.25,
			spaceBetween: 15
		},
	},
}
);

try {
	historyYearSlider.controller.control = historyContentSlider;
	historyContentSlider.controller.control = historyYearSlider;
} catch (error) {

}

const historyContentCustom = new Swiper(".box-bottom .swiper-container",{
	slidesPerView: 6,
	navigation: {
		prevEl: ".box-top .swiper-prev",
		nextEl: ".box-top .swiper-next"
	},
	breakpoints: {
		1024: {
			slidesPerView: 4,
		},
		768: {
			slidesPerView: 2,
		},
	},
})

const staffSlider = new Swiper(
	".staff-slider .swiper-container", {
	slidesPerView: 3.75,
	spaceBetween: 75,
	breakpoints: {
		1024: {
			slidesPerView: 3,
			spaceBetween: 20
		},
		768: {
			slidesPerView: 2,
			spaceBetween: 20
		},
		576: {
			slidesPerView: 1,
			spaceBetween: 20
		},
	},
	navigation: {
		prevEl: ".about-5 .swiper-prev",
		nextEl: ".about-5 .swiper-next",
	},
}
);

var partnerSlider = new Swiper(".about-partner-slider .swiper-container", {
	slidesPerView: 3,
	slidesPerColumn: 2,
	navigation: {
		prevEl: ".about-partner-slider .swiper-prev",
		nextEl: ".about-partner-slider .swiper-next",
	},
	breakpoints: {
		1024: {
			slidesPerView: 3,
		},
		768: {
			slidesPerView: 2,
		},
	},
});


var elmSticky = document.querySelector('#nav-sticky');
var ms = new MenuSpy(elmSticky,{
	threshold: 150
});